import React from 'react'
import {Image} from 'semantic-ui-react'

import icon from '../../images/icons8-back-26.png'

const Back = () => (
  <Image
    size="mini"
    src={icon}
    alt="back"
  />
)

export default Back
