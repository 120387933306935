import React from 'react'
import {useStaticQuery, graphql } from 'gatsby'
import {Responsive} from 'semantic-ui-react'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'


const Nav = ({location}) => {
 
  const {strapiHeader} = useStaticQuery(
    graphql`
    query {
      strapiHeader(id: {ne: ""}) {
        TopLink
      }
    }
    `,
  )

  return (
    <>
    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
      <MobileMenu
        location={location}
        header={strapiHeader}
      />
    </Responsive>
    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
      <DesktopMenu
        location={location}
        header={strapiHeader}
      />
    </Responsive>
  </>
)}

export default Nav
