import React, {useState, useEffect} from 'react'
import {Link, withPrefix} from 'gatsby'
import {Menu} from 'semantic-ui-react'

import Logo from './Logo'
import Back from './Back'

const MobileMenu = ({location, header}) => {
    const {pathname} = location
    const [activeItem, setActiveItem] = useState(pathname)
  
    useEffect(() => {
      setActiveItem(pathname)
    }, [pathname])

    const atHome = activeItem === withPrefix('/')
    const atProductPage = activeItem.startsWith('/product/')
    const previous = atProductPage ? `/category/${location.category.id}` : '/'
  
    return (
        <Menu size="huge">
          <Menu.Item
            active={atHome}
            as={Link}
            to="/"
            header
          >
            <Logo />
            {atHome && (header.TopLink)}
          </Menu.Item>
          {!atHome && (
            <Menu.Item
                active={true}
                as={Link}
                to={previous}
                header
            >
                <Back />
            </Menu.Item>
          )}
      </Menu>
    )
  }
  
  export default MobileMenu