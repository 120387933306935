import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {Segment, Container, Grid, List, Header} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import 'semantic-ui-css/semantic.min.css'
import 'react-image-lightbox/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Footer = () => {
  const {strapiFooter} = useStaticQuery(
    graphql`
    query {
      strapiFooter(id: {ne: ""}) {
        Header
        Body
        Twitter
        Facebook
        Email
        WhatsappNumber
      }
    }
    `,
  )

  const twitterLink = (
    <a href={strapiFooter.Twitter} alt="twitter link">
      Twitter
    </a>
  )
  const facebookLink = (
    <a href={strapiFooter.Facebook} alt="facebook link">
      Facebook
    </a>
  )
  const emailLink = (
    <a href={`mailto:${strapiFooter.Email}`} alt="email link">
      Email
    </a>
  )
  const whatsappLink = (
    <a href={`https://wa.me/${strapiFooter.WhatsappNumber}`} alt="whatsapp link" target="_blank" rel="noreferrer">
      Whatsapp
    </a>
  )

  return (
    <Segment
      vertical
      style={{
        padding: '4em 0em',
        marginTop: '3em',
        borderTop: '1px solid #f2f2f2',
      }}
    >

      <Container text>
        <Grid stackable>
          <Grid.Row>
            {/* <Grid.Column width={5}>
              <Header as="h4" content="Services" />
              <List>
                <List.Item as={Link} to="/">
                  {strapiFooter.OurProducts}
                </List.Item>
              </List>
            </Grid.Column> */}
            <Grid.Column width={16}>
              <Header as="h4">{strapiFooter.Header}</Header>
              <ReactMarkdown source={strapiFooter.Body} />
              <List horizontal style={{display: 'flex'}}>
                {!!strapiFooter.Twitter && 
                (
                    <List.Item
                    icon="twitter"
                    style={{display: 'flex'}}
                    content={twitterLink}
                  />
                )}
                {strapiFooter.Facebook && 
                (
                  <List.Item
                  icon="facebook"
                  style={{display: 'flex'}}
                  content={facebookLink}
                  />
                )}
                {strapiFooter.Email && 
                (
                  <List.Item
                  icon="mail"
                  style={{display: 'flex'}}
                  content={emailLink}
                  />
                )}
                {strapiFooter.WhatsappNumber && 
                (
                  <List.Item
                  icon="whatsapp"
                  style={{display: 'flex'}}
                  content={whatsappLink}
                  />
                )}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  )
}

export default Footer
