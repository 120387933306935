/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'
import { useLocation } from "@reach/router"

function SEO({lang, title, metadata}) {
  const { pathname } = useLocation();
  const {strapiMetadata} = useStaticQuery(
    graphql`
    query {
      strapiMetadata(id: {ne: ""}) {
        Description
        Locale
        SiteName
        SiteUrl
        Title
        TwitterCard
        TwitterDescription
        TwitterImage {
          publicURL
        }
        TwitterTitle
        Image {
          publicURL
          extension
        }
      }
    }
    `,
  );
  const completeUrl = (path) => {
    return strapiMetadata.SiteUrl + path
  }
  let meta = [];
  meta.push({
    property: `og:site_name`,
    content: strapiMetadata.SiteName,
  });
  meta.push(        {
    property: `og:type`,
    content: `website`,
  });
  meta.push(        {
    property: `og:url`,
    content: completeUrl(pathname),
  });
  meta.push(        {
    property: `og:locale`,
    content: strapiMetadata.Locale,
  });
  meta.push({
    name: `twitter:card`,
    content: strapiMetadata.TwitterCard,
  });
  meta.push({
    property: `msapplication-TileColor`,
    content: `#da532c`,
  });
  meta.push({
    property: `theme-color`,
    content: `#ffffff`,
  });

  if(metadata){
    meta.push({
      name: `description`,
      content: metadata.description,
    });
    meta.push({
      property: `og:description`,
      content: metadata.description,
    });
    meta.push({
      name: `twitter:description`,
      content: metadata.description,
    })
    meta.push({
      property: `og:title`,
      content: metadata.title,
    });
    meta.push({
      name: `twitter:title`,
      content: metadata.title,
    });
    meta.push({
      property: `og:image`,
      content: completeUrl(metadata.image),
    });
    meta.push({
      name: `twitter:image`,
      content: completeUrl(metadata.image),
    });

  }
  else{
    meta.push({
      property: `description`,
      content: strapiMetadata.Description,
    });
    meta.push({
      property: `og:description`,
      content: strapiMetadata.Description,
    });
    meta.push({
      name: `twitter:description`,
      content: strapiMetadata.TwitterDescription,
    })
    meta.push({
      property: `og:title`,
      content: strapiMetadata.Title,
    });
    meta.push({
      name: `twitter:title`,
      content: strapiMetadata.TwitterTitle,
    });
    meta.push({
      property: `og:image`,
      content: completeUrl(strapiMetadata.Image.publicURL),
    });
    meta.push({
      name: `twitter:image`,
      content: completeUrl(strapiMetadata.TwitterImage.publicURL),
    });
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${strapiMetadata.SiteName}`}
      meta={meta}
    >
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'es'
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
