import React from "react"
import PropTypes from "prop-types"
import Headroom from 'react-headroom'
import {Container} from 'semantic-ui-react'

import Nav from "../Nav"
import Footer from '../Footer'

const Layout = ({ location, children }) => {
  return (
    <>
      <Headroom
        upTolerance={10}
        downTolerance={10}
        style={{zIndex: '20', height: '6.5em'}}
      >
        <Nav location={location}/>
      </Headroom>
      <Container text>{children}</Container>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
