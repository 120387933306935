import React from 'react'
import {Image} from 'semantic-ui-react'

import icon from '../../images/icons8-home.svg'

const Logo = () => (
  <Image
    size="mini"
    src={icon}
    style={{marginRight: '1em'}}
    alt="home"
  />
)

export default Logo
