import React, {useState, useEffect} from 'react'
import {Link, withPrefix} from 'gatsby'
import {Menu, Container} from 'semantic-ui-react'

import Logo from './Logo'

const DesktopMenu = ({location, header}) => {
    const {pathname} = location
    const [activeItem, setActiveItem] = useState(pathname)
  
    useEffect(() => {
      setActiveItem(pathname)
    }, [pathname])

    const atHome = activeItem === withPrefix('/')
  
    return (
      <Menu size="huge" pointing>
        <Container text>
          <Menu.Item
            active={atHome}
            as={Link}
            to="/"
            header
          >
            <Logo />
            {atHome && (header.TopLink)}
          </Menu.Item>
          {location.category && (
            <Menu.Item
                active={!location.product}
                as={Link}
                to={`/category/${location.category.id}/`}
                header
            >
                {location.category.Name}
            </Menu.Item>
          )}
          {location.product && (
            <Menu.Item
                active={!!location.product}
                as={Link}
                to={`/product/${location.product.strapiId}/`}
                header
            >
                {location.product.Name}
            </Menu.Item>
          )}
        </Container>
      </Menu>
    )
  }
  
  export default DesktopMenu
